import aboutApi, { AboutApp } from "@/api/about";
import { defineStore } from "pinia";

type AppState = {
    display: {
        fullscreen: boolean;
    };
    about: AboutApp;
};

const useAppStore = defineStore("app", {
    state: (): AppState => ({
        display: {
            fullscreen: false,
        },
        about: {
            configuration: {
                rollbar: {
                    accessToken: "",
                    enabled: false,
                    environment: "",
                },
            },
            version: "",
        },
    }),
    actions: {
        async load() {
            const response = await aboutApi.get();
            this.$state.about = response;
        },
    },
});

export default useAppStore;
