import useAuthStore from "@/stores/auth";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: RouteRecordRaw[] = [
    {
        name: "Home",
        path: "/",
        component: () => import("@v/home/index.vue"),
    },
    {
        name: "Log In",
        path: "/auth/login",
        component: () => import("@v/auth/login.vue"),
    },
    {
        name: "Estimates",
        path: "/estimates",
        component: () => import("@v/estimates/index.vue"),
    },
    {
        name: "Orders",
        path: "/orders",
        component: () => import("@v/orders/OrderManagement.vue"),
    },
    {
        name: "Admin",
        path: "/admin",
        component: () => import("@v/admin/index.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ["/auth/login"];
    const authRequired = !publicPages.includes(to.path);
    const auth = useAuthStore();

    if (authRequired && !auth.user) {
        auth.redirect = to.fullPath;
        return "/auth/login";
    }
});

export default router;
