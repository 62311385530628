<template>
    <v-card flat color="transparent">
        <v-layout>
            <template v-if="authStore.user && !appStore.display.fullscreen">
                <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

                <v-app-bar color="primary" prominent>
                    <v-app-bar-nav-icon v-if="mobile" variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                    <v-toolbar-title class="title"><v-icon icon="mdi-bird" class="mr-3"></v-icon>Coracias v{{ appStore.about.version }}</v-toolbar-title>

                    <v-spacer></v-spacer>
                </v-app-bar>

                <v-navigation-drawer :expand-on-hover="!mobile" :rail="!mobile" v-model="drawer">
                    <v-list>
                        <v-list-item :prepend-avatar="`https://www.gravatar.com/avatar/${md5(email)}`" :title="username"> </v-list-item>
                    </v-list>

                    <v-list>
                        <v-list-item v-for="(item, index) in items" :key="index" @click="item.click()">
                            <template v-slot:prepend>
                                <v-icon :icon="item.icon"></v-icon>
                            </template>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-navigation-drawer>
            </template>
            <v-main>
                <router-view></router-view>
            </v-main>
            <v-snackbar v-model="updatePopup" timeout="-1">
                A new version is currently available!
                <template v-slot:actions>
                    <v-btn flat color="white" @click="updateServiceWorker(true)">Update</v-btn>
                </template>
            </v-snackbar>
        </v-layout>
    </v-card>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";
import useAuthStore from "@/stores/auth";
import useAppStore from "@/stores/app";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { useDisplay } from "vuetify";
import { useRegisterSW } from "virtual:pwa-register/vue";
import { watch } from "vue";

const authStore = useAuthStore();
const appStore = useAppStore();
const router = useRouter();
const email = computed(() => authStore.user?.email);
const username = computed(() => authStore.user?.userName);
const { mobile } = useDisplay();
const drawer = ref(true);
const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
const updatePopup = ref(false);
const items = [
    {
        title: "Home",
        icon: "mdi-home",
        click() {
            router.push("/");
        },
    },
    {
        title: "Estimates",
        icon: "mdi-folder",
        click() {
            router.push("/estimates");
        },
    },
    {
        title: "Orders",
        icon: "mdi-account-multiple",
        click() {
            router.push("/orders");
        },
    },
    {
        title: "Admin",
        icon: "mdi-application",
        click() {
            router.push("/admin");
        },
    }
        ]

function md5(email: string | null | undefined) {
    return email;
}

onMounted(async () => {
    await authStore.authenticated();
    if (authStore.user) {
        router.push("/");
    }

    drawer.value = !mobile.value;
});

watch(needRefresh, () => {
    updatePopup.value = true;
});
watch(offlineReady, () => {
    updatePopup.value = true;
});
</script>

<style lang="scss">
.title {
    .v-toolbar-title__placeholder {
        display: inline-block;
    }
}
</style>
