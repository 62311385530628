import { AboutApp } from "@/api/about";
import Rollbar from "rollbar";
import { App, ComponentPublicInstance } from "vue";

export default {
    install(app: App, config: AboutApp) {
        const rollbar = new Rollbar({
            accessToken: config.configuration.rollbar.accessToken,
            captureUncaught: true,
            captureUnhandledRejections: true,
            payload: {
                environment: config.configuration.rollbar.environment,
                client: {
                    javascript: {
                        source_map_enabled: true,
                        guess_uncaught_frames: true,
                        code_version: config.version,
                    },
                },
            },
        });
        app.config.globalProperties.$rollbar = rollbar;
        app.config.errorHandler = (err: any, _vm: ComponentPublicInstance | null, _info: string) => {
            rollbar.error(err);
            throw err;
        };
    },
};
