import { createApp, PropType } from "vue";
import App from "./App.vue";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import { aliases, mdi } from "vuetify/iconsets/mdi";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "@mdi/font/css/materialdesignicons.css";
import router from "./routes";
import "./styles/main.scss";
import { createPinia } from "pinia";
import rollbar from "./plugins/rollbar";
import useAppStore from "./stores/app";
import { useRegisterSW } from "virtual:pwa-register/vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

declare global {
    function idProp<T extends string>(): PropType<T>;
    function objProp<T>(): T extends string ? false : PropType<Readonly<T>>;
    function arrProp<T>(): PropType<readonly T[]>;
}

globalThis.idProp = function idProp<T extends string>(): PropType<T> {
    return String as unknown as PropType<T>;
};

globalThis.objProp = function objProp<T>() {
    return Object as unknown as T extends string ? false : PropType<Readonly<T>>;
};

globalThis.arrProp = function arrProp<T>() {
    return Array as PropType<readonly T[]>;
};

const { needRefresh, updateServiceWorker } = useRegisterSW({
    onRegisteredSW(_url, registration) {
        registration &&
            window.setInterval(() => {
                registration.update();
            }, 60 * 1000);
        if (needRefresh) {
            window.setTimeout(() => {
                updateServiceWorker(true);
            }, 1000);
        }
    },
    onRegisterError(_error) {},
    onNeedRefresh() {},
    onOfflineReady() {},
});

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
});

const pinia = createPinia();
const app = createApp(App).use(pinia);

const appStore = useAppStore();
await appStore.load();
app.use(rollbar, appStore.about).use(router).use(vuetify);
app.mount("#app");
