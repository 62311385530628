import axios from "./axios";

export interface AboutApp {
    configuration: {
        rollbar: {
            environment: string;
            enabled: boolean;
            accessToken: string;
        };
    };
    version: string;
}

type AboutApi = {
    get(): Promise<AboutApp>;
};

const aboutApi: AboutApi = {
    get: async function (): Promise<AboutApp> {
        const response = await axios.get<AboutApp>("about");
        return response.data;
    },
};

export default aboutApi;
