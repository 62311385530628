import axios from "./axios";

export interface LoginResponse {
    user: {
        id: string;
        email: string | null;
        userName: string;
    } | null;
    error: string | null;
}

type AuthApi = {
    authenticated(): Promise<LoginResponse>;
    login(username: string, password: string): Promise<LoginResponse>;
};

const authApi: AuthApi = {
    authenticated: async function (): Promise<LoginResponse> {
        try {
            const response = await axios.get("authentication/authenticated");
            return { user: response.data, error: null };
        } catch (error: any) {
            if (error?.response?.status == 401) {
                return { user: null, error: error };
            }

            throw error;
        }
    },
    login: async function (username: string, password: string): Promise<LoginResponse> {
        try {
            const response = await axios.post("authentication/login", {
                username: username,
                password: password,
            });

            return { user: response.data, error: null };
        } catch (error: any) {
            if (error?.response?.status == 401) {
                return { user: null, error: error };
            }

            throw error;
        }
    },
};

export default authApi;
