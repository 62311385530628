import { defineStore } from "pinia";
import authApi from "@a/auth";

type AuthState = {
    user: {
        id: string;
        userName: string;
        email: string | null;
    } | null;
    redirect: string | null;
};

const useAuthStore = defineStore("auth", {
    state: (): AuthState => ({
        user: null,
        redirect: null,
    }),
    actions: {
        async authenticated(): Promise<void> {
            const loginResponse = await authApi.authenticated();
            this.$state.user = loginResponse.user;
        },
        async login(username: string, password: string): Promise<string | null> {
            const loginResponse = await authApi.login(username, password);
            this.$state.user = loginResponse.user;
            return loginResponse.error;
        },
    },
});

export default useAuthStore;
